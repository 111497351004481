import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/user/Login.vue'
import Signup from './views/user/Signup.vue'
import Profile from './views/user/Profile.vue'
import Subscription from './views/user/Subscription.vue'
import TemporaryAccess from './views/user/TemporaryAccess.vue'
import MollieBilling from './views/user/MollieBilling'
import ForgotPassword from './views/user/ForgotPassword'
import ResetPassword from './views/user/ResetPassword.vue'
import RenewRequest from './views/user/RenewRequest.vue'
// import SmsValidation from './views/user/SmsValidation'
import smsValidationSubscribe from './views/user/smsValidationSubscribe.vue'
import SmsVerifyPhoneOtp from './views/user/SmsVerifyPhoneOtp'
import EmailValidation from './views/user/EmailValidation'
import ForgotPasswordValidation from './views/user/ForgotPasswordValidation'
import Admin from './views/admin/Admin.vue'
import Attribution from './views/admin/Attribution.vue'
import Parkings from './views/user/Parkings.vue'
import MyParkings from './views/user/MyParkings.vue'
import AdminParking from './views/admin/Parking.vue'
import AdminSession from './views/admin/Session.vue'
import AdminExport from './views/admin/Export.vue'
import Municipality from './views/admin/Municipality.vue'
import Payment from './views/user/Payment.vue'
import Coupon from './views/admin/Coupon.vue'
import AccountMenu from './components/profile/AccountMenu.vue'
import { some } from 'lodash/fp'
import { setChildToken } from '@/store/modules/profile'
import HowItWorks from './views/user-session/HowItWorks.vue';
import HowItPay from './views/user-session/HowItPay'
import HowSessionStart from './views/user-session/HowSessionStart'
import SessionStartQr from './views/user-session/SessionStartQr'
import EnteringQr from './views/user-session/EnteringQr'
import SessionEndQr from './views/user-session/SessionEndQr'
import SeeYouSoon from './views/user-session/SeeYouSoon'
import { APP_ENV_SESSION } from '@/config'

Vue.use(Router)

const titlePrefix = 'CycloParking'
const sessionRoutes = APP_ENV_SESSION ? [{
	path: '/how-it-pay',
	name: 'HowItPay',
	component: HowItPay,
	meta: { title: `${titlePrefix} - How it pay` },
},
	{
		path: '/how-session-start',
		name: 'HowSessionStart',
		component: HowSessionStart,
		meta: { title: `${titlePrefix} - How session start` },
	},
	{
		path: '/session-start-qr',
		name: 'SessionStartQr',
		component: SessionStartQr,
		meta: { title: `${titlePrefix} - Session start qr` },
	},
	{
		path: '/entering-qr',
		name: 'EnteringQr',
		component: EnteringQr,
		meta: { title: `${titlePrefix} - Entering qr` },
	},
	{
		path: '/session-end-qr',
		name: 'SessionEndQr',
		component: SessionEndQr,
		meta: { title: `${titlePrefix} - Session End qr` },
	},
	{
		path: '/see-you-soon',
		name: 'SeeYouSoon',
		component: SeeYouSoon,
		meta: { title: `${titlePrefix} - Session Detail` },
	},
	{
		path: '/session/:sessionId',
		name: 'SessionDetail',
		component: SeeYouSoon,
		meta: { title: `${titlePrefix} - Session Detail` },
	},
	{
		path: '/session/:sessionId/thankyou',
		name: 'SessionDetail',
		component: SeeYouSoon,
		meta: { title: `${titlePrefix} - Session Detail` },
	}] : []

const router = new Router({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/profile',
			name: 'profile',
			component: Profile,
			meta: { title: `${titlePrefix} - Profile` },
			alias: '/',
			props: (r) => ({
				editable: Boolean(r.query.edit),
				proposal: r.query.proposal,
			}),
		},
		{
			path: '/verifyPhone',
			name: 'verifyPhone',
			component: SmsVerifyPhoneOtp,
			props: true,
			meta: { title: `${titlePrefix} - Verify Phone Validation` },
		},
		{
			path: '/smsValidation',
			name: 'smsValidation',
			component: smsValidationSubscribe,
			props: true,
			meta: { title: `${titlePrefix} - Sms Validation` },
		},
		{
			path: '/emailValidation',
			name: 'emailValidation',
			component: EmailValidation,
			props: true,
			meta: { title: `${titlePrefix} - Email Validation` },
		},
		{
			path: '/forgotPasswordValidation',
			name: 'ForgotPasswordValidation',
			component: ForgotPasswordValidation,
			props: true,
			meta: { title: `${titlePrefix} - Reset Password Validation` },
		},
		{
			path: '/accountMenu',
			name: 'accountMenu',
			component: AccountMenu,
			props: true,
			meta: { title: `${titlePrefix} - Account Menu` },
		},
		{
			path: '/signup/:subType?',
			name: 'signup',
			component: Signup,
			meta: { title: `${titlePrefix} - Signup` },
		},
		{
			path: '/login/:subType?',
			name: 'login',
			component: Login,
			meta: { title: `${titlePrefix} - Login` },
		},
		{
			path: '/payment',
			name: 'payment',
			component: Payment,
			meta: { title: `${titlePrefix} - Payment` },
		},
		{
			path: '/subscription/:subType?',
			name: 'subscription',
			component: Subscription,
			meta: { title: `${titlePrefix} - Subscription` },
			props: (r) => ({
				step: r.query.step,
				subId: Number(r.query.subId),
				subType: r.params.subType,
				proposalId: Number(r.query.proposal),
			}),
		},
		{
			path: '/temporaryAccess/:parkingCode?',
			name: 'temporaryAccess',
			component: TemporaryAccess,
			meta: { title: `${titlePrefix} - TemporaryAccess` },
			props: (r) => ({
				parkingCode: r.params.parkingCode,
				temporaryAccessId: Number(r.query.id),
			}),
		},
		{
			path: '/forgotPassword',
			name: 'forgotPassword',
			component: ForgotPassword,
			meta: { title: `${titlePrefix} - Forgot Password` },
		},
		{
			path: '/resetPassword/:code?',
			name: 'resetPassword',
			component: ResetPassword,
			meta: { title: `${titlePrefix} - Reset Password` },
		},
		{
			path: '/renewRequest/:code?',
			name: 'renewRequest',
			component: RenewRequest,
			meta: { title: `${titlePrefix} - Renew Request` },
		},
		{
			path: '/parkings',
			name: 'parkings',
			component: Parkings,
			meta: { title: `${titlePrefix} - Parkings` },
			props: (r) => ({
				focus: Number(r.params.focus),
			}),
		},
		{
			path: '/myparkings',
			name: 'myparkings',
			component: MyParkings,
			meta: { title: `${titlePrefix} - My Parkings` },
		},
		{
			path: '/go/:subType',
			redirect: '/signup/:subType',
		},
		{
			path: '/claim',
			redirect: '/profile',
		},
		{
			path: '/mollie-billing',
			name: 'MollieBilling',
			component: MollieBilling,
			meta: { title: `${titlePrefix} - Mollie Billing` },
		},
		{
			path: '*',
			redirect: '/',
		},

		// parking session
		{
			path: '/how-it-works',
			name: 'howitworks',
			component: HowItWorks,
			meta: { title: `${titlePrefix} - How it works` },
		}, ...sessionRoutes,


		// Admin routes
		{
			path: '/admin',
			name: 'admin',
			component: Admin,
			meta: { title: `${titlePrefix} - Admin panel`, admin: true },
		},
		{
			path: '/attribution',
			name: 'attribution',
			component: Attribution,
			meta: { title: `${titlePrefix} - Attribution panel`, admin: true },
		},
		{
			path: '/adminParking',
			name: 'adminParking',
			component: AdminParking,
			meta: { title: `${titlePrefix} - Parking panel`, admin: true },
		},
		{
			path: '/adminExport',
			name: 'adminExport',
			component: AdminExport,
			meta: { title: `${titlePrefix} - Export panel`, admin: true },
		},
		{
			path: '/municipality',
			name: 'municipality',
			component: Municipality,
			meta: { title: `${titlePrefix} - Municipality panel`, admin: true },
		},
		{
			path: '/coupon',
			name: 'coupon',
			component: Coupon,
			meta: { title: `${titlePrefix} - Coupon panel`, admin: true },
		},
		{
			path: '/adminSession',
			name: 'adminSession',
			component: AdminSession,
			meta: { title: `${titlePrefix} - Session panel`, admin: true },
		},
	],
})

router.beforeEach((to, from, next) => {
	// Redirect to login page if not logged in and trying to access a restricted page
	const publicPages = [
		'/login',
		'/signup',
		'/forgotPassword',
		'/resetPassword',
		'/parkings',
		'/renewRequest',
		'/how-it-works',
		// "/forgotPasswordValidation",

	]

	const authRequired = !some((page) => new RegExp(page).test(to.path), publicPages)
	const loggedIn = localStorage.getItem('cycloToken')

	document.title = to.meta.title

	// eslint-disable-next-line no-debugger
	// debugger

	if (to.query.family) {
		setChildToken(to.query.family)

		return next({ ...to, query: { ...to.query, family: undefined } })
	}

	if (authRequired && !loggedIn) {
		return next({ name: 'login', query: { from: to.fullPath } })
	}

	// if (loggedIn && !authRequired) {
	// 	return next('/')
	// }

	return next()
})
export default router
