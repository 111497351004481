<template>
	<div>
		<Modal
			v-if="local.keyModalOpen"
			:modalOpen="local.keyModalOpen"
			:loading="isLoading"
			@confirm="doUpdateKeyForSub"
			@cancel="closeKeyModal"
			@close="closeKeyModal"
		>
			<input v-model="local.editCard.cardNumber" type="text" />
			<input v-model="local.editCard.cardType" type="text" />
		</Modal>
		<Loading v-if="isLoading" />
		<Menu activeMenu="admin" />
		<div class="admin">
			<div class="admin-content-block">
				<h2 style="display: flex">
					{{ $t('admin.searchTitle') }}
				</h2>
				<form
					action=""
					class="admin-edit-form admin-search-container"
					data-vv-scope="admin-form"
					@submit.prevent="doFindUsers()"
				>
					<div class="admin-search-container-input-box">
						<input
							ref="search"
							v-model="local.query"
							v-validate="'min:3'"
							:placeholder="$t('admin.searchFieldPlaceholder')"
							:class="{ error: errors.has('admin-form.search') }"
							name="search"
							type="text"
							class="input-field admin-input-field input admin-search-field"
						/>
						<p v-show="errors.has('admin-form.search')" class="input-error">
							{{ errors.first('admin-form.search') }}
						</p>
					</div>
					<div
						class="button blue activatable admin-action-button search-button"
						tabindex="0"
						@mousedown="doFindUsers($event)"
						@keyup.enter="doFindUsers($event)"
					>
						{{ $t('admin.searchButtonText') }}
					</div>
					<div
						class="button blue activatable admin-action-button search-button"
						tabindex="0"
						@mousedown="doFindUsers($event, 'bigparking')"
						@keyup.enter="doFindUsers($event, 'bigparking')"
					>
						{{ $t('admin.showBigParkingUsers') }}
					</div>

					<div
						v-if="false"
						class="button blue activatable admin-action-button"
						tabindex="0"
						@mousedown="doSyncWithWow($event)"
						@keyup.enter="doSyncWithWow($event)"
					>
						Sync with WOW
					</div>
				</form>
				<div class="admin-flex-space-between">
					<div>
						<Checkbox v-model="local.onlyActive" class="profile-checkbox" name="newsletter">
							<span slot="label" class="checkbox-text text">
								{{ $t('admin.activeOnlyCheckbox') }}
							</span>
						</Checkbox>
					</div>
					<div
						v-clipboard:copy="foundUserEmails"
						v-clipboard:success="copySuccess"
						:class="{ disabled: filteredFoundUsers.length === 0 }"
						class="button blue activatable admin-action-button search-button"
					>
						copy emails
					</div>
				</div>
			</div>
		</div>
		<div class="admin">
			<section>
				<header class="admin-item-row header">
					<div class="admin-item-col header">email</div>
					<div class="admin-item-col header small">name</div>
					<div class="admin-item-col header small">first name</div>
					<div class="admin-item-col header small">phone</div>
					<div class="admin-item-col header tiny">lang</div>
					<div class="admin-item-col header tiny">stat</div>
					<div class="admin-item-col header smaller">created at</div>
				</header>
				<UserResult
					v-for="(item, index) in filteredFoundUsers"
					:key="index"
					:class="{ selected: item.id === user.id }"
					:user="item"
				/>
			</section>
		</div>
		<div v-if="user.email" class="admin padbottom">
			<section>
				<h4>
					DETAILS <span v-if="user.parentId"> (Child of: {{ user.parent.email }})</span>
				</h4>
				<div class="admin-item-wrapper">
					<div class="admin-item-info-row">
						<div>
							<strong>Name:</strong> {{ user.lastName }} {{ user.firstName }} (id: {{ user.id }})
						</div>
						<div><strong>Email: </strong> {{ user.email }}</div>
						<div><strong>Language: </strong> {{ user.language }}</div>
					</div>

					<div class="admin-item-info-row">
						<div><strong>Mobib: </strong> {{ user.mobib || 'not defined' }}</div>
						<div><strong>Bank account: </strong> {{ user.bankAccount || 'not defined' }}</div>
						<div><strong>Newsletter: </strong> {{ user.newsletter ? 'Yes' : 'no' }}</div>
					</div>
					<hr />
					<div class="admin-item-info-row">
						<div>
							<div v-for="(address, index) in user.addresses" :key="index">
								<strong>{{ address.addressType }}: </strong>
								{{ structuredAddress(address) }}
							</div>
							<div v-if="user.legacyAddress"><strong>Legacy: </strong>{{ user.legacyAddress }}</div>
						</div>

						<div class="button-list">
							<button
								v-if="!user.parentId"
								class="button red"
								type="button"
								@click="doDeleteUser()"
							>
								{{ $t('admin.deleteUser') }}
							</button>
							<button class="button blue" type="button" @click="toUserAdminView()">
								{{ $t('admin.editUser') }}
							</button>
						</div>
					</div>
				</div>

				<!-- SUBSCRIPTIONS -->
				<section v-if="subsToShow(orderedSubscriptions).length > 0">
					<h4>{{ $t('admin.subscriptionsTitle') }}</h4>
					<UserSubscription
						v-for="(item, index) in subsToShow(orderedSubscriptions)"
						:key="index"
						:subscription="item"
					/>
				</section>

				<!-- HISTORY LOG -->
				<section v-if="user.historyLogs.length > 0">
					<h4>{{ $t('admin.historyLogTitle') }}</h4>
					<div v-for="(item, index) in orderedLogs()" :key="index" class="admin-item-row">
						<div class="admin-item-col medium">
							{{ date(item.createdAt) }}
						</div>
						<div class="admin-item-col">
							{{ item.action }}
						</div>
					</div>
				</section>

				<!-- REQUESTS -->
				<section v-if="(orderedRequests || []).length > 0">
					<h4>{{ $t('admin.requestsTitle') }}</h4>
					<div v-for="(item, index) in orderedRequests" :key="index" class="admin-item-row">
						<div class="admin-item-col medium">
							{{ $t(`request.types.${item.type}`) }}
						</div>
						<div v-if="item.addressId" class="admin-item-col">
							Address: {{ item.address.addressType }}
						</div>
						<div v-else class="admin-item-col">
							{{ item.comment.replace(/-\s*$/g, '') }}
						</div>
						<div class="admin-item-col small">
							{{ item.fromChild ? item.childName : '' }}
						</div>
						<div class="admin-item-col small">
							{{ requestStatusName(item.status) }}
						</div>
						<date-picker
							v-if="item.status === 1"
							v-model="item.requestedAt"
							:clearable="false"
							class="admin-item-col small"
							lang="en"
							format="DD-MM-YYYY"
							confirm
							confirmText="Update"
							inputClass="input-field small"
							@confirm="doUpdateRequestDate($event, item.id)"
						/>
						<div v-else class="admin-item-col small">
							{{ date(item.requestedAt) }}
						</div>
						<div v-if="item.status === 1">
							<button
								class="delete-request-button button tiny red"
								type="button"
								@click="doDeleteRequest(item.id)"
							>
								X
							</button>
						</div>
					</div>
				</section>

				<!-- PROPOSALS -->
				<section v-if="(user.proposals || []).length > 0" class="user-subscriptions">
					<h4>{{ $t('admin.proposalsTitle') }}</h4>
					<div
						v-for="(item, index) in orderedProposals"
						:key="index"
						class="user-subscription admin-item-row"
					>
						<div class="admin-item-col medium">
							{{ item.id }}
						</div>
						<div class="admin-item-col medium">
							{{ item.parking.code }}
						</div>
						<div class="admin-item-col medium">
							{{ item.comment }}
						</div>
						<div class="admin-item-col small">
							{{ item.fromChild ? item.childName : '' }}
						</div>
						<div class="admin-item-col small">
							{{ proposalStatusName(item.status) }}
						</div>
						<div class="admin-item-col small">
							{{ date(item.lastStatusChangedAt) }}
						</div>
					</div>
				</section>
			</section>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Loading from '@/components/Loading'
import Modal from '@/components/Modal'
import UserResult from '@/components/admin/UserResult'
import UserSubscription from '@/components/admin/UserSubscription'
import Menu from '@/components/admin/Menu'
import { date, getStatusName, tryString } from '@/helpers'
import { subsToShow } from '@/store/modules/subscription'
import DatePicker from 'vue2-datepicker'
import ValidationRequired from '@/mixins/validation-required'
import { PROPOSAL_STATES, REQUEST_STATES, SUBSCRIPTION_STATES, languages } from '@/config'
import { map, join, filter, sortBy, reverse } from 'lodash/fp'
import Checkbox from '@/components/form/Checkbox.vue'

// import { ToggleButton } from 'vue-js-toggle-button'
// import Dropdown from '@/components/form/Dropdown.vue'

// import { logger } from '@/logger'

export default {
	name: 'Admin',
	components: {
		Loading,
		Modal,
		UserResult,
		UserSubscription,
		DatePicker,
		Menu,
		Checkbox,

		// ToggleButton,
		// Dropdown,
	},
	mixins: [ValidationRequired],
	data() {
		return {
			languages,
			local: {
				language: null,
				newsletter: null,
				query: '',
				mobib: '',
				email: '',
				bankAccount: '',
				keyModalOpen: false,
				editSub: null,
				editCard: null,
				onlyActive: false,
			},
			showMessage: false,
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			isLoading: (state) => state.admin.loading,
			foundUsers: (state) => state.admin.users.all,
			user: (state) => state.admin.userForm.user,
			alert: (state) => state.alert,
		}),

		...mapGetters('admin', {
			orderedProposals: 'orderedProposals',
			orderedRequests: 'orderedUserRequests',
			orderedSubscriptions: 'orderedUserSubscriptions',
		}),
		filteredFoundUsers() {
			return this.local.onlyActive
				? filter(
						(u) =>
							filter((s) => parseInt(s.status, 10) === SUBSCRIPTION_STATES.active, u.subscriptions)
								.length > 0,
						this.foundUsers
				  )
				: this.foundUsers
		},
		foundUserEmails() {
			return join('; ')(
				map((u) => (u.parentId ? u.parent.email : u.email))(this.filteredFoundUsers)
			)
		},
		cleaveOptions() {
			if (this.user.mobib && this.user.mobib.length > 1 && this.user.mobib[1] === '0') {
				// eslint-disable-next-line no-magic-numbers
				return { blocks: [4, 4, 4, 4, 2], uppercase: true }
			} else {
				// eslint-disable-next-line no-magic-numbers
				return { blocks: [2, 4, 4, 4, 4], uppercase: true }
			}
		},
	},
	watch: {
		'user.mobib'(newVal) {
			this.local.mobib = newVal
		},
		'user.email'(newVal) {
			this.local.email = newVal
		},
		'user.bankAccount'(newVal) {
			this.local.bankAccount = newVal
		},
		user(newVal) {
			// console.log('user change', newVal, newVal.language, 'jaja')

			this.local.language = newVal.language
			this.local.newsletter = newVal.newsletter
		},
		'alert.message'(newVal) {
			if (newVal) {
				this.flash(this.alert.message, this.alert.type)
				this.clearAlert()
			}
		},
	},
	mounted() {
		// console.log('remove thiss')
		// const search = 'stijn.pint@gmail.com'

		const search = this.$route.params.search

		if (search) {
			this.local.query = search
			this.findUsers(search).then(() => {
				if (this.foundUsers[0]) {
					this.showUserForm(this.foundUsers[0])
				}
			})
		}
	},

	methods: {
		...mapActions({
			findUsers: 'admin/findUsers',
			updateMobibForUser: 'admin/updateMobibForUser',
			updateEmailForUser: 'admin/updateEmailForUser',
			updateNewsletterForUser: 'admin/updateNewsletterForUser',
			updateLanguageForUser: 'admin/updateLanguageForUser',
			updateRequestDate: 'admin/updateRequestDate',
			deleteRequest: 'admin/deleteRequest',
			updateKeyForSub: 'admin/updateKeyForSub',
			syncSubscriptionsWithWow: 'admin/syncSubscriptionsWithWow',
			retrySubscription: 'admin/retrySubscription',
			sendResetLink: 'admin/sendResetLink',
			deleteUser: 'admin/deleteUser',
			updateBankAccountForUser: 'admin/updateBankAccountForUser',
			editUserByAdmin: 'profile/editUserByAdmin',
		}),
		...mapActions('alert', {
			clearAlert: 'clear',
		}),
		...mapMutations('admin', {
			showUserForm: 'showUserForm',
		}),

		doFindUsers(e, queryOverride = null) {
			if (e) {
				e.preventDefault()
			}

			if (!this.errors.has('admin-form.search')) {
				this.findUsers(queryOverride || this.local.query)
			}
		},
		toggleModal() {
			this.showMessage = !this.showMessage
		},
		doUpdateMobib() {
			this.updateMobibForUser({ cardNumber: this.local.mobib, userId: this.user.id })
		},

		doUpdateBankAccount() {
			this.updateBankAccountForUser({
				bankAccount: this.local.bankAccount,
				userId: this.user.id,
			}).then(() => this.showUserForm(this.foundUsers.find((u) => u.id === this.user.id)))
		},
		doUpdatePhone() {
			this.updatePhoneNumberForUser({ phoneNumber: this.local.phoneNumber, userId: this.user.id })
		},
		doUpdateEmail() {
			this.updateEmailForUser({ email: this.local.email, userId: this.user.id })
		},

		doUpdateRequestDate(e, reqId) {
			this.updateRequestDate({ requestDate: e.getTime(), requestId: reqId })
		},

		doDeleteRequest(reqId) {
			this.deleteRequest({ requestId: reqId })
		},

		// personalCardNumber(sub) {
		// 	return sub.personalCard ? sub.personalCard.cardNumber : 'N/A'
		// },

		doSyncWithWow() {
			if (confirm('Are you sure you want to launch the synchronization with WOW?')) {
				this.syncSubscriptionsWithWow({ simulation: false })
			}
		},

		// ///// NOT YET USED	////////
		openKeyModal(item) {
			this.local.keyModalOpen = true
			this.local.editSub = item.id
			this.local.editCard = Object.assign({}, item.personalCard) || {}
		},
		closeKeyModal() {
			this.local.keyModalOpen = false
		},

		// ////////////////////////

		doSendResetLink() {
			if (confirm('Are you sure you want to send a password reset link to this user?')) {
				this.resetLink = this.sendResetLink({ userId: this.user.id })
			}
		},

		doDeleteUser() {
			if (confirm('Are you sure you want to completely remove this user from the CYCLO systems?')) {
				return this.deleteUser({ userId: this.user.id })
			} else {
				return false
			}
		},

		proposalStatusName(status) {
			return this.getStatusName(PROPOSAL_STATES, status)
		},
		requestStatusName(status) {
			return this.getStatusName(REQUEST_STATES, status)
		},
		structuredAddress(address) {
			return `${address.streetName} ${address.houseNumber} ${tryString(
				address.busNumber
			)} ${tryString(address.postalCode, { prefix: ', ' })} ${tryString(address.city, {
				prefix: address.postalCode ? '' : ', ',
			})}`
		},

		copySuccess() {
			alert('addresses copied!')
		},

		updateNewsletter(e) {
			this.local.newsletter = e.value

			return this.updateNewsletterForUser({ newsletter: e.value, userId: this.user.id })
		},

		async updateLanguage(e) {
			if (e.key !== this.local.language) {
				this.local.language = e.key
				await this.updateLanguageForUser({ language: e.key, userId: this.user.id })
			}
		},

		findCurrentLanguage() {
			return languages.find((l) => l.key === (this.local.language || this.profileLocal.language))
		},

		orderedLogs() {
			return reverse(sortBy((l) => l.createdAt, this.user.historyLogs))
		},

		toUserAdminView() {
			this.editUserByAdmin(this.user)
		},

		date,
		tryString,
		subsToShow,
		getStatusName,
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/admin';
@import '../../assets/scss/components/info';

.user-subscriptions {
	width: 100%;
}
.user-subscription {
	// display: flex;
	div {
		flex: 1;
	}
}
.mobib-field-container {
	position: relative;
	input {
		min-width: 400px;
	}
}
.subscription-key-confirm {
	margin-left: 5px;
}

.mx-datepicker {
	width: unset;
}

.admin-action-button {
	font-size: 14px;
	padding: 0 10px;
}

.password-reset-link {
	margin-bottom: 20px;
	float: right;
}

.toggle-button-label {
	margin: 0 20px;
}

.delete-request-button {
	padding: 0 3px;
}
.button-list button {
	&:first-child {
		margin-left: 0;
		margin-right: 10px;
	}
}
</style>
