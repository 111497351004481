<template>
	<div :class="`${getClassName('wrapper')} autocomplete-wrapper`">
		<input
			:id="id"
			ref="input"
			v-model="type"
			type="text"
			:class="inputClass"
			:placeholder="placeholder"
			:name="name"
			autocomplete="on"
			@input="handleInput"
			@dblclick="handleDoubleClick"
			@blur="handleBlur"
			@keydown="handleKeyDown"
			@focus="handleFocus"
		/>

		<div
			v-show="showList && json.length"
			:class="`${getClassName('list')} autocomplete autocomplete-list`"
		>
			<ul>
				<li v-for="(data, i) in json" :key="i" :class="activeClass(i)">
					<a href="#" @click.prevent="selectList(data)" @mousemove="mousemove(i)">
						<div v-if="onShouldRenderChild" v-html="onShouldRenderChild(data)"></div>
						<div v-if="!onShouldRenderChild">
							<b class="autocomplete-anchor-text">{{ deepValue(data, anchor) }}</b>
							<span class="autocomplete-anchor-label">{{ formatAddress(data) }}</span>
						</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { formatAddressObj } from '../../helpers'


export default {
	props: {
		id: String,
		name: String,
		className: String,
		inputClass: String,
		classes: {
			type: Object,
			default: () => ({
				wrapper: false,
				input: false,
				list: false,
				item: false,
			}),
		},
		placeholder: String,
		required: Boolean,

		// Intial Value
		initValue: {
			type: String,
			default: '',
		},

		// Manual List
		options: Array,

		// Filter After Get the data
		filterByAnchor: {
			type: Boolean,
			default: true,
		},

		// Anchor of list
		anchor: {
			type: String,
			required: true,
		},

		// Label of list
		label: String,

		// Debounce time
		debounce: Number,

		// ajax URL will be fetched
		url: {
			type: String,
			required: true,
		},

		// query param
		param: {
			type: String,
			default: 'text',
		},

		encodeParams: {
			type: Boolean,
			default: true,
		},

		// Custom Params
		customParams: Object,

		// Custom Headers
		customHeaders: Object,

		// minimum length
		min: {
			type: Number,
			default: 0,
		},

		// Create a custom template from data.
		onShouldRenderChild: Function,

		// Process the result before retrieveng the result array.
		process: Function,

		// Callback
		onInput: Function,
		onShow: Function,
		onBlur: Function,
		onHide: Function,
		onFocus: Function,
		onSelect: Function,
		onBeforeAjax: Function,
		onAjaxProgress: Function,
		onAjaxLoaded: Function,
		onShouldGetData: Function,
	},

	data() {
		return {
			showList: false,
			type: this.initValue,
			json: [],
			focusList: '',
			debounceTask: undefined,
		}
	},

	watch: {
		options(newVal) {
			if (this.filterByAnchor) {
				const { type, anchor } = this
				const regex = new RegExp(`${type}`, 'i')
				const filtered = newVal.filter((item) => {
					const found = item[anchor].search(regex) !== -1

					return found
				})

				this.json = filtered
			} else {
				this.json = newVal
			}
		},
		initValue(newValue){
			this.type = newValue
		}
	},

	created() {
		// Sync parent model with initValue Props
		this.type = this.initValue ? this.initValue : null
	},

	mounted() {
		if (this.required) {
			this.$refs.input.setAttribute('required', this.required)
		}
	},
	unmounted(){
		this.clearInput()
	},

	methods: {
		formatAddress: (data) => formatAddressObj(data?.properties?.address),
		getClassName(part) {
			const { classes, className } = this

			if (classes[part]) {
				return `${classes[part]}`
			}

			return className ? `${className}-${part}` : ''
		},

		// Netralize Autocomplete
		clearInput() {
			this.showList = false
			this.type = ''
			this.json = []
			this.focusList = ''
		},

		// Get the original data
		cleanUp(data) {
			return JSON.parse(JSON.stringify(data))
		},

		/* ==============================
        INPUT EVENTS
      ============================= */
		handleInput(e) {
			const { value } = e.target;

			this.showList = true;

			// Callback Event
			if (this.onInput) {
				this.onInput(value);
			}

			// If Debounce
			if (this.debounce) {
				if (this.debounceTask !== undefined) {
					clearTimeout(this.debounceTask);
				}

				this.debounceTask = setTimeout(() => {
					return this.getData(value);
				}, this.debounce);
			} else {
				return this.getData(value);
			}

			return null; // Add this line to return a value at the end of the method
		},

		handleKeyDown(e) {
			const key = e.keyCode

			// Disable when list isn't showing up
			if (!this.showList) {
				return
			}

			// Key List
			const DOWN = 40
			const UP = 38
			const ENTER = 13
			const ESC = 27

			// Prevent Default for Prevent Cursor Move & Form Submit
			switch (key) {
				case DOWN:
					e.preventDefault()
					this.focusList++
					break
				case UP:
					e.preventDefault()
					this.focusList--
					break
				case ENTER:
					e.preventDefault()
					this.selectList(this.json[this.focusList])
					this.showList = false
					break
				case ESC:
					this.showList = false
					break
			}

			const listLength = this.json.length - 1
			const outOfRangeBottom = this.focusList > listLength
			const outOfRangeTop = this.focusList < 0
			const topItemIndex = 0
			const bottomItemIndex = listLength

			let nextFocusList = this.focusList

			if (outOfRangeBottom) {
				nextFocusList = topItemIndex
			}

			if (outOfRangeTop) {
				nextFocusList = bottomItemIndex
			}

			this.focusList = nextFocusList
		},

		setValue(val) {
			this.type = val
		},

		/* ==============================
        LIST EVENTS
      ============================= */

		handleDoubleClick() {
			this.json = [];
			this.getData('');

			// Callback Event
			return this.onShow ? this.onShow() : null;
			this.showList = true;
		},

		handleBlur(e) {
			// Callback Event
			if (this.onBlur) {
				this.onBlur(e);
			}
			setTimeout(() => {
				// Callback Event
				if (this.onHide) {
					this.onHide();
				}
				this.showList = false;
			}, 250);
		},

		handleFocus(e) {
			this.focusList = 0

			// Callback Event
			if (this.onFocus) {
				this.onFocus(e);
			}
		},

		mousemove(i) {
			this.focusList = i
		},

		activeClass(i) {
			const focusClass = i === this.focusList ? 'focus-list' : ''

			return `${focusClass}`
		},

		selectList(data) {
			// Deep clone of the original object
			// const clean = this.cleanUp(data)

			// Put the selected data to type (model)
			// console.log("Data: " + JSON.stringify(data))
			this.type = data?.properties?.address?.road

			// Hide List
			this.showList = false

			// Callback Event
			this.onSelect ? this.onSelect(data) : null
		},

		deepValue(obj, path) {
			const arrayPath = path.split('.')

			for (let i = 0; i < arrayPath.length; i++) {
				obj = obj[arrayPath[i]]
			}

			return obj
		},

		/* ==============================
        AJAX EVENTS
      ============================= */

		composeParams(val) {
			const encode = (val) => (this.encodeParams ? encodeURIComponent(val) : val)
			let params = `${this.param}=${encode(val)}`

			if (this.customParams) {
				Object.keys(this.customParams).forEach((key) => {
					params += `&${key}=${encode(this.customParams[key])}`
				})
			}

			return params
		},

		composeHeader(ajax) {
			if (this.customHeaders) {
				Object.keys(this.customHeaders).forEach((key) => {
					ajax.setRequestHeader(key, this.customHeaders[key])
				})
			}
			else {
				ajax.setRequestHeader('Content-Type', 'application/json')
			}
		},

		doAjax(val) {
			// Callback Event
			this.onBeforeAjax ? this.onBeforeAjax(val) : null

			// Compose Params
			const params = this.composeParams(val)

			// Init Ajax
			const ajax = new XMLHttpRequest()

			ajax.open('GET', `${this.url}?${params}`, true)
      		ajax.setRequestHeader('Accept', 'application/json, application/geo+json, application/gpx+xml image/png;charset=utf-8');
			this.composeHeader(ajax)

			// Callback Event
			ajax.addEventListener('progress', (data) => {
				if (data.lengthComputable && this.onAjaxProgress) {
					this.onAjaxProgress(data)
				}
			})

			// On Done
			ajax.addEventListener('loadend', (e) => {
				const { responseText } = e.target
				const json = JSON.parse(responseText)

				// Callback Event
				this.onAjaxLoaded ? this.onAjaxLoaded(json) : null
				this.json = this.process ? this.process(json) : json.features
			})

			// Send Ajax
			ajax.send()
		},

		getData(value) {

			if (value.length < this.min || !this.url) {
				return
			}

			if (this.onShouldGetData) {
				this.manualGetData(value)
			} else {
				this.doAjax(value)
			}
		},

		// Do Ajax Manually, so user can do whatever he want
		manualGetData(val) {
			const task = this.onShouldGetData(val)

			if (task && task.then) {
				return task.then((options) => {
					this.json = options
				})
			}
		},
	},
}
</script>
