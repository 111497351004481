// session.js

import graphqlClient from '../../api/db'
import gql from 'graphql-tag'
import { extractGraphqlError } from '@/helpers'
import router from '@/router'
import { cloneDeep } from 'lodash/fp'

// Initial state
const initialState = () => ({
	sessionCreate: [],
	sessionStop: [],
	sessionEnd: [],
	sessionActive: [],
	userLiveSession: null,
	userSessions: [],
	isLoading: false,
	error: null,
	mandates: [],
	token: '',
})

const state = initialState()

// GraphQL Queries
const userSessionDetailQuery = gql`
	query SessionById($sessionByIdId: Int) {
		sessionById(id: $sessionByIdId) {
			bikes
			createdAt
			duration
			endTime
			id
			parking {
				id
				code
				name
				legacyAddress {
					en
					fr
					nl
				}
			}
			parkingId
			paymentMethod
			sessionId
			sessionQRCodes {
				id
				expiresIn
				qrCode
				qrType
				usage
				used
				usedAt
				userSessionId
			}
			startTime
			totalAmountPaid
			userId
		}
	}
`
const userSessionsQuery = gql`
	query UserSessions($limit: Int, $offset: Int, $userId: Int) {
		userSessions(limit: $limit, offset: $offset, userId: $userId) {
			bikes
			createdAt
			duration
			id
			endTime
			parking {
				id
				code
				name
				legacyAddress {
					en
					fr
					nl
				}
			}
			paymentMethod
			sessionId
			sessionQRCodes {
				id
				expiresIn
				qrCode
				qrType
				usage
				used
				usedAt
				userSessionId
			}
			startTime
			totalAmountPaid
			userId
		}
	}
`
const getAllMandates = gql`
	query GetAllMandates {
		getAllMandates {
			createdAt
			customerId
			default
			details {
				cardExpiryDate
				cardFingerprint
				cardHolder
				cardLabel
				cardNumber
			}
			id
			mandateReference
			method
			mode
			signatureDate
			status
		}
	}
`
const userLiveSessionQuery = gql`
	query UserLiveSession($userId: Int) {
		userLiveSession(userId: $userId) {
			bikes
			createdAt
			duration
			endTime
			id
			parking {
				id
				code
				name
				legacyAddress {
					en
					fr
					nl
				}
				sessionConfig {
					id
					parkingId
					perHourCost
					subscriptionFreeHours
				}
			}
			paymentMethod
			sessionId
			sessionQRCodes {
				id
				expiresIn
				qrCode
				qrType
				usage
				used
				usedAt
				userSessionId
			}
			startTime
			totalAmountPaid
			userId
		}
		serverTime
	}
`
// GraphQL mutations
const startNewSessionMutation = gql`
	mutation StartNewSession($props: SessionProps) {
		startNewSession(props: $props) {
			session {
				id
				userId
				parkingId
				sessionQRCodes {
					id
					userSessionId
					qrType
					qrCode
					used
					usage
					usedAt
					expiresIn
				}
				bikes
				sessionId
				paymentMethod
				startTime
				endTime
				duration
				totalAmountPaid
				createdAt
			}
		}
	}
`

const generateQrCode = gql`
	mutation GenerateQrCode($sessionId: Int, $codeType: String) {
		generateQrCode(sessionId: $sessionId, codeType: $codeType) {
			id
			userId
			parkingId
			sessionQRCodes {
				id
				userSessionId
				qrType
				qrCode
				used
				usage
				usedAt
				expiresIn
			}
			bikes
			sessionId
			paymentMethod
			startTime
			endTime
			duration
			totalAmountPaid
			createdAt
		}
	}
`

const endSessionMutation = gql`
	mutation SessionEnd($sessionId: Int) {
		sessionEnd(sessionId: $sessionId) {
			payment {
				status
			}
			session {
				id
				userId
				parkingId
				sessionQRCodes {
					id
					userSessionId
					qrType
					qrCode
					used
					usage
					usedAt
					expiresIn
				}
				bikes
				sessionId
				paymentMethod
				startTime
				endTime
				duration
				totalAmountPaid
				createdAt
			}
		}
	}
`
const updateSessionMutation = gql`
	mutation SessionUpdate($props: SessionProps) {
		sessionUpdate(props: $props)
	}
`

// Actions
const actions = {
	async startNewSession({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: startNewSessionMutation,
				variables: { props: sessionProps },
			})
			const { session } = response.data.startNewSession
			commit('setSessionId', session.sessionId)
			commit('addSession', session)
			return session
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
		} finally {
			commit('setLoading', false)
		}
	},

	async generateQrCode({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			const mutationResponse = await graphqlClient.mutate({
				mutation: generateQrCode,
				variables: { sessionId: sessionProps.sessionId, codeType: sessionProps.codeType },
			})
			const sessionData = mutationResponse.data.generateQrCode
			const enterQr = sessionData.sessionQRCodes.filter((b) => b.qrType === 'ENT')
			commit('resetState')
			if (sessionProps.codeType === 'ENT') {
				sessionData.sessionQRCodes = enterQr
				commit('stopSession', sessionData)
			} else {
				return sessionData
			}
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
		} finally {
			commit('setLoading', false)
		}
	},

	async endSession({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: endSessionMutation,
				variables: { sessionId: sessionProps },
			})
			const { session, payment } = response.data.sessionEnd
			return payment
			//const session = response.data.startNewSession.session
			//commit('setSessionId', session.sessionId)
			//commit('addSession', session)
			//return session
		} catch ({ message }) {
			console.log('message', message)
			commit('setError', extractGraphqlError(message))
			return { status: 'failed' }
		} finally {
			commit('setLoading', false)
		}
	},

	async getSessionById({ commit, dispatch }, sessionProps) {
		commit('setLoading', true)
		try {
			const queryResponse = await graphqlClient.query({
				query: gql`
					query SessionById($sessionByIdId: Int) {
						sessionById(id: $sessionByIdId) {
							id
							userId
							parking {
								id
								code
								name
								legacyAddress {
									en
									fr
									nl
								}
								sessionConfig {
									id
									parkingId
									perHourCost
									subscriptionFreeHours
								}
							}
							parkingId
							sessionQRCodes {
								id
								userSessionId
								qrType
								qrCode
								used
								usage
								usedAt
								expiresIn
							}
							bikes
							sessionId
							paymentMethod
							startTime
							endTime
							active
							duration
							totalAmountPaid
							createdAt
						}
					}
				`,
				variables: { sessionByIdId: sessionProps.sessionId },
				fetchPolicy: 'network-only',
			})
			// reset state before initialize again
			commit('resetState')
			const sessionData = queryResponse.data.sessionById
			// filter Qr data based on status
			const enterExitQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'ENXT')
			const enterQr = sessionData.sessionQRCodes.filter((b) => b.qrType === 'ENT')
			const exitQr = sessionData.sessionQRCodes.filter((c) => c.qrType === 'EXT')

			// check existing qr and push into relative state
			if (enterExitQr.length > 0 && enterExitQr[0].used < enterExitQr[0].usage) {
				sessionData.sessionQRCodes = enterExitQr
				commit('addSession', sessionData)
				//return sessionData;
			} else if (
				enterExitQr.length > 0 &&
				enterExitQr[0].used === enterExitQr[0].usage &&
				enterQr.length === 0
			) {
				commit('activeSession', sessionData)
				if (router.history.current.name !== 'HowSessionStart') {
					router.push({ name: 'HowSessionStart' })
				}
				// return sessionData;
			} else if (
				enterQr.length > 0 &&
				enterQr[enterQr.length - 1].used < enterQr[enterQr.length - 1].usage &&
				exitQr.length === 0
			) {
				sessionData.sessionQRCodes = enterQr
				commit('stopSession', sessionData)
				//return sessionData;
			} else if (
				enterQr.length > 0 &&
				enterQr[enterQr.length - 1].used === enterQr[enterQr.length - 1].usage &&
				exitQr.length === 0
			) {
				const sessionnewProps = {
					sessionId: sessionProps.sessionId,
					codeType: 'EXT', // Provide the actual code type
				}
				const response = await dispatch('generateQrCode', sessionnewProps)
				if (response) {
					router.push({ name: 'SessionEndQr' })
				}
			} else if (
				exitQr.length > 0 &&
				exitQr[exitQr.length - 1].used < exitQr[exitQr.length - 1].usage
			) {
				sessionData.sessionQRCodes = exitQr
				commit('endSession', queryResponse.data.sessionById)
				if (router.history.current.name !== 'SessionEndQr') {
					router.push({ name: 'SessionEndQr' })
				}
				//return queryResponse.data.sessionById;
			} else if (
				exitQr.length > 0 &&
				exitQr[exitQr.length - 1].used === exitQr[exitQr.length - 1].usage
			) {
				const sessionendProps = {
					sessionId: sessionProps.sessionId,
				}
				//await dispatch('endSession', sessionendProps);
				if (router.history.current.name !== 'SessionDetail') {
					router.push('/session/' + sessionendProps.sessionId + '/thankyou')
					localStorage.removeItem('sessionId')
					localStorage.removeItem('sessionBikes')
				}
			}
		} catch (error) {
			commit('setError', extractGraphqlError(error.message))
		} finally {
			commit('setLoading', false)
		}
	},
	async getUserLiveSession({ commit }, userId) {
		commit('setLoading', true)
		try {
			const queryResponse = await graphqlClient.query({
				query: userLiveSessionQuery,
				variables: { userId },
				fetchPolicy: 'no-cache',
			})
			const userLiveSessionData = queryResponse.data.userLiveSession
			commit('userLiveSession', userLiveSessionData)
			commit('setServerTime', queryResponse.data.serverTime, { root: true })
			return userLiveSessionData
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
		} finally {
			commit('setLoading', false)
		}
	},
	async getUserSessions({ commit }, userId) {
		commit('setLoading', true)
		try {
			const mutationResponse = await graphqlClient.query({
				query: userSessionsQuery,
				variables: { userId },
			})
			const userSessionsData = mutationResponse.data.userSessions
			commit('userSessions', userSessionsData)
			return userSessionsData
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
		} finally {
			commit('setLoading', false)
		}
	},
	async getUserSessionDetail({ commit }, sessionByIdId) {
		commit('setLoading', true)
		try {
			const mutationResponse = await graphqlClient.query({
				query: userSessionDetailQuery,
				variables: { sessionByIdId },
			})
			const userSessionData = mutationResponse.data.sessionById
			// commit('userSessionDetail', userSessionData);
			return userSessionData
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
		} finally {
			commit('setLoading', false)
		}
	},
	getLiveSessionDetailLink({ commit, state }) {
		let activeSessionDetailLink = 'HowSessionStart'
		if (state.userLiveSession?.id) {
			const sessionData = cloneDeep(state.userLiveSession)
			const enterExitQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'ENXT')
			const enterQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'ENT')
			const exitQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'EXT')
			if (enterExitQr[0].used < enterExitQr[0].usage) {
				sessionData.sessionQRCodes = enterExitQr
				commit('session/addSession', sessionData, { root: true })
				activeSessionDetailLink = 'SessionStartQr'
			} else if (enterExitQr[0].used === enterExitQr[0].usage && enterQr.length === 0) {
				commit('session/activeSession', sessionData, { root: true })
				activeSessionDetailLink = 'HowSessionStart'
			} else if (enterQr.length > 0 && enterQr[0].used < enterQr[0].usage) {
				sessionData.sessionQRCodes = enterQr
				commit('session/stopSession', sessionData, { root: true })
				activeSessionDetailLink = 'EnteringQr'
			} else if (exitQr[0].used < exitQr[0].usage) {
				sessionData.sessionQRCodes = exitQr
				commit('session/endSession', queryResponse.data.userLiveSession, { root: true })
				activeSessionDetailLink = 'SessionEndQr'
			}
			// set active session id into local storage
			localStorage.setItem('sessionId', sessionData.sessionId)
		}
		return activeSessionDetailLink
	},
	async getAllMandates({ commit }) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.query({
				query: getAllMandates,
			})
			commit('setLoading', false)
			commit('setMandates', response.data.getAllMandates)
		} catch (e) {
			// logger.error('catching error in update payment method', e)
			commit('setLoading', false)
		}
	},
	async updateSession({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: updateSessionMutation,
				variables: { props: sessionProps },
			})
			const res = response.data.sessionUpdate
			return res
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
		} finally {
			commit('setLoading', false)
		}
	},
	/**
	 * Calculate session amount from start time till the provided end time. To be used for active session as its not ended yet.
	 */
	getSessionAmountTill({ commit, state }, endTime) {
		if (!state.userLiveSession.startTime) {
			return 0
		}
		const noOfBikes = state.userLiveSession.bikes || 1
		let diffMs = Math.abs(endTime - state.userLiveSession.startTime)
		const diffDays = Math.floor(diffMs / 86400000) // days
		const diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
		const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
		const totalHours = Math.ceil(diffDays * 24 + diffHrs + diffMins / 60)
		const parkingConfig = state.userLiveSession.parking.sessionConfig
		let totalAmount =
			totalHours > parkingConfig.subscriptionFreeHours
				? (
						parkingConfig.perHourCost *
						(totalHours - parkingConfig.subscriptionFreeHours) *
						noOfBikes
				  ).toFixed(2)
				: 0
		return totalAmount
	},
}

// Mutations
const mutations = {
	resetState(state) {
		Object.assign(state, initialState())
	},
	addSession(state, session) {
		state.sessionCreate.push(session)
		state.error = null
	},
	stopSession(state, session) {
		state.sessionStop.push(session)
		state.error = null
	},
	endSession(state, session) {
		state.sessionEnd.push(session)
		state.error = null
	},
	activeSession(state, session) {
		state.sessionActive.push(session)
		state.error = null
	},
	userLiveSession(state, session) {
		state.userLiveSession = session
		state.error = null
	},
	userSessions(state, sessions) {
		state.userSessions = sessions
		state.error = null
	},
	setSessionId(state, sessionId) {
		localStorage.setItem('sessionId', sessionId)
	},
	setLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	setError(state, error) {
		state.error = error
	},
	setMandates(state, mandates) {
		state.mandates = mandates
		state.error = null
	},
	setToken(state, token) {
		state.token = token
		state.error = null
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
