<template>
	<div :style="{ position: 'relative' }" class="admin-item-row invoice-items-row">
		<div class="admin-item-col">
			{{ invoiceItemType(item.type) }}
		</div>
		<div class="admin-item-col smaller">€ {{ formattedAmount }}</div>
		<div class="admin-item-col small">
			{{ item.paid ? 'PAID' : 'NOT PAID' }}
		</div>
		<div class="admin-item-col small">
			{{ item.paid ? date(item.paidAt) : '' }}
		</div>
		<div class="admin-item-col">
			{{ date(item.createdAt) }}
		</div>
		<div v-if="item.refunds.length > 0" class="admin-item-col">
			{{ refundInfo(item.refunds) }}
		</div>
		<template v-else>
			<div v-if="item.paid && item.type === invoiceItemTypes.NEW_KEY" class="admin-item-col">
				<form
					class="admin-item-info-form inline-refund"
					@submit.prevent="$emit('refundNewKey', item.id)"
				>
					<button class="button blue tiny subscription-deposit-action">R(S)</button>
				</form>
				<form
					class="admin-item-info-form inline-refund"
					@submit.prevent="$emit('manualRefundNewKey', item.id)"
				>
					<button class="button blue tiny subscription-deposit-action">R(M)</button>
				</form>
			</div>
			<div v-else class="admin-item-col">no refunds</div>
		</template>
		<div class="info" @mouseenter="toggleModal" @mouseleave="toggleModal">
			<div class="info-icon">?</div>
			<div v-if="showMessage" class="info-modal">
				<div>{{ item.description }}</div>
				<div v-for="(r, index) in item.refunds" :key="index">
					€ {{ r.amount }} - {{ date(r.refundedAt) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { invoiceItemTypes } from '@/config'
import { findKey, sumBy } from 'lodash/fp'
import { date } from '@/helpers'

export default {
	name: 'UserSubInvoiceItem',
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			showMessage: false,
			invoiceItemTypes,
		}
	},
	computed: {
		formattedAmount() {
			if (this.item.mollieId !== null) {
				return (this.item.amount * 100).toFixed(2)
			} else if (
				this.item.mollieId === null &&
				this.item.stripeId === null &&
				this.item.amount < 1
			) {
				return (this.item.amount * 100).toFixed(2)
			} else {
				return this.item.amount.toFixed(2)
			}
		},
	},
	methods: {
		invoiceItemType(type) {
			const t = findKey((v) => v === type, invoiceItemTypes)

			return t
		},

		refundInfo(refunds) {
			const amount = sumBy('amount', refunds)
			if (this.item.mollieId !== null) {
				return `refunded €${(amount * 100).toFixed(2)}`
			} else if (this.item.mollieId === null && this.item.stripeId === null && amount < 1) {
				return `refunded €${(amount * 100).toFixed(2)}`
			} else {
				return `refunded €${amount}`
			}
		},

		toggleModal() {
			this.showMessage = !this.showMessage
		},
		date,
	},
}
</script>

<style lang="scss" scoped>
// @import '../../assets/scss/pages/admin';
@import '../../assets/scss/components/info';

form.inline-refund {
	display: inline-block;
	button {
		padding: 0px 10px;
	}
}
</style>
