<template>
	<div class="bg">
		<Loading v-if="sessionActive.length === 0 || isLoading" />
		<activesession
			@openModal="openModal"
			:sessionActive="sessionActive"
			:totalAmountPaid="totalAmountPaid"
			v-else="sessionActive.length > 0"
		/>
		<SessionModal :showModal="showModal" @close="closeModal" @confirm="confirmAction">
			<template v-slot:title>
				<h1>{{ modalContent.title }}</h1>
			</template>
			<template v-slot:content>
				<p>
					{{ modalContent.description }}
				</p>
				<div>
					<div class="alert-message">
						<img
							src="../../assets//images/session//icon_warning_black.svg"
							alt="Add Circle Icon"
							class="icon-svg alert-img"
						/>
						<span class="alert">{{ modalContent.alertText }}</span>
					</div>
				</div>
			</template>
			<template v-slot:confirmButton>
				<button @click="confirmAction">{{$t('sessionParking.modal.endSession.modalConfirm')}}</button>
			</template>
			<template v-slot:cancelButton>
				<a class="cancel" @click="closeModal">{{$t('sessionParking.modal.endSession.modalClose')}}</a>
			</template>
		</SessionModal>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PreviousButton from '@/components/PreviousButton.vue'
import activesession from '../../components/session/activesession.vue'
import SessionModal from '@/components/SessionModal.vue'
import SessionRequired from '@/mixins/session-required'
import Loading from '@/components/Loading.vue'

export default {
	name: 'HowSessionStart',
	components: {
		activesession,
		PreviousButton,
		SessionModal,
		Loading,
	},
	mixins: [SessionRequired],
	data() {
		return {
			showModal: false,
			modalTitle: '',
			modalDescription: '',
			modalAlertText: '',
			previousRoute: null,
			sessionActive: [],
			totalAmountPaid: '0',
		}
	},
	computed: {
	modalContent() {
      if (this.previousRoute && this.previousRoute.matched.length > 0) {
        const previousRoutePath = this.previousRoute.matched[0].path;
        if (previousRoutePath === '/entering-qr') {
          return {
            title: this.$t('sessionParking.modal.expireSession.modalTitle'),
            description: this.$t('sessionParking.modal.expireSession.modalDescription'),
            alertText: this.$t('sessionParking.modal.expireSession.modalAlertText')
          };
        } else {
          return {
            title: this.$t('sessionParking.modal.endSession.modalTitle'),
            description: this.$t('sessionParking.modal.endSession.modalDescription'),
            alertText: this.$t('sessionParking.modal.endSession.modalAlertText')
          };
        }
      } else {
        return {
          title: this.$t('sessionParking.modal.endSession.modalTitle'),
          description: this.$t('sessionParking.modal.endSession.modalDescription'),
          alertText: this.$t('sessionParking.modal.endSession.modalAlertText')
        };
      }
    },
		...mapState({
			session: (state) =>
				state.session
					? state.session.sessionStop.length > 0
						? state.session.sessionStop
						: state.session.sessionActive
					: null,
			isLoading: (state) => state.session.isLoading,
			errorMessage: (state) => state.session.error,
		}),
		},
	watch: {
		session: {
			immediate: true, // Trigger the watcher immediately after component is created
			handler(newVal) {
				if (!newVal) return [] // Return if sessionActive is null or undefined
				this.sessionActive = newVal
				if (newVal) {
					this.calculateParkingFee()
				}
			},
		},
	},
	methods: {
		...mapActions('session', ['generateQrCode', 'endSession']),
		...mapActions('alert', ['error']),
		calculateParkingFee() {
			if (
				this.session &&
				this.session.length > 0 &&
				this.session[0].parking &&
				this.session[0].parking.sessionConfig
			) {
				if (this.session[0].endTime) {
					const freeHours = this.session[0].parking.sessionConfig.subscriptionFreeHours
					const perHourCost = this.session[0].parking.sessionConfig.perHourCost

					const currentTime = Date.now()
					const startTime = this.session[0].endTime
					const elapsedMs = currentTime - startTime

					// Calculate the total hours elapsed
					const elapsedHours = elapsedMs / (1000 * 60 * 60)

					// Calculate the rounded total hours elapsed (rounding up to the nearest whole number)
					const roundedElapsedHours = Math.ceil(elapsedHours)
					// Calculate the amount paid
					const totalAmountPaid = Math.max(0, roundedElapsedHours - freeHours) * perHourCost
					this.totalAmountPaid = (totalAmountPaid * this.session[0].bikes).toFixed(2)
				} else {
					const freeHours = this.session[0].parking.sessionConfig.subscriptionFreeHours
					const perHourCost = this.session[0].parking.sessionConfig.perHourCost

					const currentTime = Date.now()
					const startTime = this.session[0].startTime
					const elapsedMs = currentTime - startTime

					// Calculate the total hours elapsed
					const elapsedHours = elapsedMs / (1000 * 60 * 60)

					// Calculate the rounded total hours elapsed (rounding up to the nearest whole number)
					const roundedElapsedHours = Math.ceil(elapsedHours)
					// Calculate the amount paid
					const totalAmountPaid = Math.max(0, roundedElapsedHours - freeHours) * perHourCost
					this.totalAmountPaid = (totalAmountPaid * this.session[0].bikes).toFixed(2)
				}
			}
		},
		openModal() {
			// Show the default modal
			this.showModal = true
		},
		closeModal() {
			this.showModal = false
		},
		async confirmAction() {
			// Get sessionId from local storage
			const sessionId = localStorage.getItem('sessionId')

			if (!sessionId) {
				console.error('Session ID not found in local storage')
				return
			}

			// Convert sessionId to an integer
			const numericSessionId = parseInt(sessionId, 10)

			// Check if the conversion is successful
			if (isNaN(numericSessionId)) {
				console.error('Invalid session ID:', sessionId)
				return
			}

			// Call the action with the required sessionProps
			const sessionProps = {
				sessionId: numericSessionId,
				codeType: 'ENT', // Provide the actual code type
			}

			// Perform action when confirmed
			try {
				// first need to confirm the payment if it is paid or failed
				const res = await this.endSession(parseInt(sessionId))
				if (res.status === 'failed') {
					this.error(this.errorMessage)
					setTimeout(() => {
						this.$router.push({
							path: '/how-it-pay',
							query: {
								parkingId: this.sessionActive[0].parkingId,
								session: true,
							},
						})
					}, 3000)
				} else if (res.status === 'paid') {
					// Call the action with the provided sessionProps
					const response = await this.generateQrCode(sessionProps)
					// Handle the response data here
					this.$router.push('/entering-qr') // Update route based on your requirements
				}
			} catch (error) {
				console.error('Error stopping session:', error)
			}

			this.closeModal()
		},
	},
	mounted() {
		this.intervalId = setInterval(() => {
			this.calculateParkingFee()
		}, 300000)
	},
	beforeDestroy() {
		clearInterval(this.intervalId)
	},
	beforeRouteEnter(to, from, next) {
		// Pass the previous route to the component
		next((vm) => {
			vm.previousRoute = from
			// Open the modal if returning from 'entering-qr'
			if (from.path === '/entering-qr') {
				vm.showModal = true
			}
		})
	},
	beforeRouteUpdate(to, from, next) {
		this.previousRoute = from

		// Open the modal if returning from 'entering-qr'
		if (from.path === '/entering-qr') {
			this.showModal = true
		}

		next()
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';
.mollie {
	width: 30%;
	/* position: relative; */
	margin: 0px auto;
}
.bg {
	padding-bottom: 62px;
}

@media screen and (max-width: 768px) {
	.mollie {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	.roww {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}
}
</style>
